import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import queryString from 'query-string'
import { Classes } from '@blueprintjs/core'
import classNames from 'classnames'

import { CarrierSearchField } from 'browser/components/atomic-elements/molecules/fields/select-field/carrier-search-field'
import { AuthenticationPage } from 'browser/components/atomic-elements/organisms/authentication-page/authentication-page'
import { ErrorWrapper } from 'browser/components/atomic-elements/atoms/error-wrapper/error-wrapper'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Head } from 'browser/components/atomic-elements/atoms/head/head'
import { browserHistory } from 'browser/history'
import apis from 'browser/app/models/apis'

export const CompanyCode: React.FC = (props: any) => {
  // default to the company code if provided in the query string
  const parsedParams = queryString.parse(window.location.search)
  const [companyCode, setCompanyCode] = useState<string>(parsedParams.companyCode)
  const [errorText, setErrorText] = useState<string>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleContinueClicked = () => {
    setIsLoading(true)
    setErrorText(null)
    apis.validateCompanyCode(companyCode).then((response) => {
      browserHistory.push({
        pathname: '/register-credential',
        search: window.location.search,
        state: {
          companyCode,
          firm: response,
          nextRoute: _.get(props.location, 'state.nextRoute'),
        },
      })
    }).catch((e) => {
      const errors = _.get(e, 'responseJSON.errors', null)
      if (errors) {
        _.forEach(errors, (error: any) => {
          if (error.field === 'companyCode') {
            setErrorText(error.message)
          }
        })
      }
    }).finally(() => {
      setIsLoading(false)
    })
  }

  useEffect(() => {
    if (!_.isEmpty(companyCode) && _.size(companyCode) >= 3) {
      handleContinueClicked()
    }
  }, [])

  return (
    <AuthenticationPage
      className='mw5-m mw5-l'
      bodyClassName='c-onboarding-body'
    >
      <Head title='Register' />
      <div className='ph4 pb4'>
        <h3 className='f3 b lh-title mt4 mb2 tc'>
          Enter Your USDOT Number
        </h3>
        <div className='lh-copy mb4 mt1 tc'>
          Your USDOT number is typically displayed on the side of your truck.
        </div>
        <ErrorWrapper error={errorText} style={{ marginBottom: 28 }}>
          <CarrierSearchField
            label='USDOT Number'
            placeholder='Search USDOT'
            errorText={errorText}
            showRemoveOption={true}
            isHorizontalLayout={false}
            clearable={true}
            value={companyCode}
            onInputChange={(value, actionMeta) => {
              const isMenuClosedAction = actionMeta?.action === 'input-blur' || actionMeta?.action === 'menu-close'
              if (isMenuClosedAction && _.isEmpty(value)) {
                // when closing the menu, react-select clears its internal
                // state, but we'll retain the current input value.
                return
              }
              setCompanyCode(value)
            }}
            onChange={setCompanyCode}
          />
        </ErrorWrapper>
        <Button
          buttonText='Continue'
          className={classNames(Classes.INTENT_PRIMARY, Classes.FILL)}
          size='large'
          isLoading={isLoading}
          onClick={handleContinueClicked}
        />
      </div>
      <Footer className="c-footer--center c-footer--transparent">
        <div className="tc pa3">
          Already a member?{' '}
          <Link
            className="b"
            data-debug-id="goToRegisterButton"
            to={{
              pathname: '/sign-in',
              search: window.location.search,
              state: {
                nextRoute: _.get(window.location, 'state.nextRoute'),
              },
            }}
          >
            Sign In
          </Link>
        </div>
      </Footer>
    </AuthenticationPage>
  )
}
